module.exports = {
  API_URL: "https://console.spsghy.co.in",
  BaseImageUrl: "https://console.spsghy.co.in/media",
  // API_URL: "http://31.187.75.67:8000",
  // API_URL: "http://localhost:8002",
  LIMIT: 10,
  google: {
    API_KEY: "",
    CLIENT_ID:
      "23144678283-oek7ncjmmrgkgmi2i56sc411gp71a8sp.apps.googleusercontent.com",
    SECRET: "",
  },
  facebook: {
    APP_ID: "",
  },
}
