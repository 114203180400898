import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap"

//i18n
import { withTranslation } from "react-i18next"
// Redux
import { connect } from "react-redux"
import { Link } from "react-router-dom"
import withRouter from "components/Common/withRouter"
// users
import user1 from "../../../assets/images/users/default_image.jpg"
const ProfileMenu = props => {
  // Declare a new state variable, which we'll call "menu"

  const name = JSON.parse(localStorage.getItem("User"))

  const [menu, setMenu] = useState(false)

  const [username, setusername] = useState("Admin")

  useEffect(() => {
    if (localStorage.getItem("authUser")) {
      if (process.env.REACT_APP_DEFAULTAUTH === "firebase") {
        const obj = JSON.parse(localStorage.getItem("authUser"))
        setusername(obj.displayName)
      } else if (
        process.env.REACT_APP_DEFAULTAUTH === "fake" ||
        process.env.REACT_APP_DEFAULTAUTH === "jwt"
      ) {
        const obj = JSON.parse(localStorage.getItem("authUser"))
        setusername(obj.username)
      }
    }
  }, [props.success])

  return (
    <React.Fragment>
      <Dropdown
        isOpen={menu}
        toggle={() => setMenu(!menu)}
        className="d-inline-block"
      >
        <DropdownToggle
          className="btn header-item "
          id="page-header-user-dropdown"
          tag="button"
        >
          <div className="d-flex m-auto">
            <p
              className={
                name === "Admin"
                  ? "d-lg-block d-none m-auto"
                  : "m-auto text-dark"
              }
            >
              {name == "Admin" ? name : name?.payload?.t_name}
            </p>
            <img
              className="rounded-circle header-profile-user"
              src={user1}
              alt="Header Avatar"
            />
          </div>
          {/* <span className="d-none d-xl-inline-block ms-2 me-1">{username}</span> */}
          {/* <i className="mdi mdi-chevron-down d-none d-xl-inline-block" /> */}
        </DropdownToggle>
        <DropdownMenu className="dropdown-menu-end">
          {name == "Admin" ? (
            ""
          ) : (
            <>
              <DropdownItem tag="a" href="/profile">
                <i className="bx bx-user font-size-16 align-middle me-1" />
                {props.t("Profile")}{" "}
              </DropdownItem>
              <DropdownItem tag="a" href="/change-password">
                <i className="bx bx-user font-size-16 align-middle me-1" />
                {props.t("Change Password")}{" "}
              </DropdownItem>
              <div className="dropdown-divider" />
            </>
          )}

          <Link to="/logout" className="dropdown-item">
            <i className="bx bx-power-off font-size-16 align-middle me-1 text-danger" />
            <span>{props.t("Logout")}</span>
          </Link>
        </DropdownMenu>
      </Dropdown>
    </React.Fragment>
  )
}

ProfileMenu.propTypes = {
  success: PropTypes.any,
  t: PropTypes.any,
}

const mapStatetoProps = state => {
  const { error, success } = state.Profile
  return { error, success }
}

export default withRouter(
  connect(mapStatetoProps, {})(withTranslation()(ProfileMenu))
)
